<template>
  <!-- :title="'Order Detail - #' + details.id" -->
  <b-modal
    id="modal-order-detail"
    :title="!details ? 'Order Detail - #' : 'Order Detail - #' + details.id"
    modal-class="p-0"
    size="xl"
    centered
    ok-only
  >
    <div v-if="details" class="row">
      <!-- {{ details.id }} -->
      <!-- left panel -->
      <div class="col-md-6">
        <div class="row">
          <div class="col">Order ID</div>
          <div class="col">{{ orderId }}</div>
        </div>
        <div class="row">
          <div class="col">Order Date</div>
          <div class="col">{{ details.date }}</div>
        </div>
        <div class="row">
          <div class="col">Payment Date</div>
          <div class="col">{{ details.payment_date_cust }}</div>
        </div>
        <div class="row">
          <div class="col">Status</div>
          <div class="col">{{ details.status }}</div>
        </div>
        <div class="row">
          <div class="col">Total Amount</div>
          <div class="col">Rp{{ formatPrice(details.sub_total) }}</div>
        </div>
        <div class="row">
          <div class="col">Total Amount After Discount</div>
          <div class="col">
            Rp{{ formatPrice(details.sub_total_with_discount) }}
          </div>
        </div>
        <div class="row">
          <div class="col">Handling Fee</div>
          <div class="col">Rp{{ formatPrice(details.handling_fee) }}</div>
        </div>
        <div class="row">
          <div class="col">Shipping</div>
          <div class="col">Rp{{ formatPrice(details.shipping) }}</div>
        </div>
        <div class="row">
          <div class="col">Voucher</div>
          <!-- <div class="col">- Rp{{ details.voucher_info.length ? details.voucher_info.map(el => el.value).reduce((a, b) => a + b) : details.voucher }}</div> -->
          <div class="col">- Rp{{ formatPrice(details.voucher_info.reduce((sum, voucher) => sum + voucher.value, 0)) }}</div>
        </div>
        <div class="row">
          <div class="col">LizPoints</div>
          <div class="col">- Rp{{ formatPrice(details.seller_loyalty) }}</div>
        </div>
        <div class="row">
          <div class="col">Dispatch Date</div>
          <div class="col">{{ details.sdate || "-" }}</div>
        </div>
        <div class="row">
          <div class="col">Buyer</div>
          <div class="col">
            {{ details.buyer_name }}
            <b v-if="details.reseller_id">(Bus. Partner)</b>
          </div>
        </div>
        <div class="row">
          <div class="col">Buyer Email</div>
          <div class="col">{{ details.buyer_email }}</div>
        </div>
        <div class="row" v-if="details.nik_number">
          <div class="col">Identity Card Number</div>
          <div class="col">{{ details.nik_number }}</div>
        </div>
        <div class="row" v-if="details.nik_name">
          <div class="col">Identity Card Name</div>
          <div class="col">{{ details.nik_name }}</div>
        </div>
        <div class="row d-lg-none">
          <div class="col"><hr /></div>
        </div>
      </div>
      <!-- right panel -->
      <div class="col-md-6">
        <div class="row">
          <div class="col"><b>Ship To:</b></div>
        </div>
        <div class="row">
          <div class="col">Name</div>
          <div class="col-7 col-md-8">{{ details.shipping_address.name }}</div>
        </div>
        <template v-if="details.courier_name">
          <div class="row">
            <div class="col">Address</div>
            <div class="col-7 col-md-8">{{ details.shipping_address.address }}</div>
          </div>
          <div class="row">
            <div class="col">City</div>
            <div class="col-7 col-md-8">{{ details.shipping_address.districts }}</div>
          </div>
          <div class="row">
            <div class="col">Sub District</div>
            <div class="col-7 col-md-8">{{ details.shipping_address.city }}</div>
          </div>
          <div class="row">
            <div class="col">Post Code</div>
            <div class="col-7 col-md-8">{{ details.shipping_address.postal_code }}</div>
          </div>
        </template>
        <div class="row">
          <div class="col">Phone</div>
          <div class="col-7 col-md-8">{{ details.courier_name ? `+62${details.shipping_address.phone}` : details.pick_up_point_contact }}</div>
        </div>
        <template v-if="details.courier_name">
          <div class="row">
            <div class="col">Courier</div>
            <div class="col-7 col-md-8">
              {{ details.courier_name }} - {{ details.courier_service_name }}
            </div>
          </div>
          <div class="row">
            <div class="col">Service Code</div>
            <div class="col-7 col-md-8">{{ details.courier_service_code }}</div>
          </div>
        </template>
        <div class="row" v-if="details.pick_up_point_address">
          <div class="col">Pick-up Point</div>
          <div class="col-7 col-md-8">
            {{ details.pick_up_point_address }} - 
            {{ details.pick_up_point_duration }} DAYS
          </div>
        </div>
        <div class="row">
          <div class="col">Order Notes</div>
          <div class="col-7 col-md-8">{{ details.order_notes }}</div>
        </div>
        <div v-if="details.pick_up_point_platform_name && details.pick_up_point_platform_name != 'OWN'" class="row">
          <div class="col-md-4">Courier Information</div>
          <div class="col d-flex">
            <div class="w-100" v-b-modal.modal-edit-courier-info>
              <textarea rows="3"
                class="form-control"
                :class="{'cursor-pointer': details.status == 'Packing'}"
                v-model="courierInfoStr"
                placeholder="Courier information"
                readonly
              ></textarea>
            </div>
          </div>
          <b-modal
            v-if="details.status == 'Packing' && isOwnMerchant"
            id="modal-edit-courier-info"
            centered hide-header hide-footer scrollable
          >
            <form @submit.prevent="saveCourierInfo" class="row g-1">
              <!-- <div class="form-group">
                <label for="courier_service_code" class="mb-0">Courier Service Code</label>
                <input id="courier_service_code" type="text" class="form-control" required v-model="details.sales_order_seller_pick_up_point.courier_service_code">
              </div> -->
              <div class="col-md-6 form-group">
                <label for="courier_name" class="mb-0">Courier Name</label>
                <input id="courier_name" type="text" placeholder="JNE, TIKI, ..." class="form-control" required v-model="details.sales_order_seller_pick_up_point.courier_name">
              </div>
              <div class="col-md-6 form-group">
                <label for="courier_service_name" class="mb-0">Service Name</label>
                <input id="courier_service_name" type="text" placeholder="REG, YES, ..." class="form-control" required v-model="details.sales_order_seller_pick_up_point.courier_service_name">
              </div>
              <!-- <div class="form-group">
                <label for="courier_code" class="mb-0">Courier Code</label>
                <input id="courier_code" type="text" class="form-control" required v-model="details.sales_order_seller_pick_up_point.courier_code">
              </div> -->
              <div class="col-12 form-group">
                <label for="tracking_number" class="mb-0">Tracking Number</label>
                <input id="tracking_number" type="text" placeholder="TRAC123456789" class="form-control" required v-model="details.sales_order_seller_pick_up_point.tracking_number">
              </div>
              <div class="col-5 col-md-3 form-group">
                <label for="cost" class="mb-0">Cost</label>
                <input id="cost" type="number" class="form-control" placeholder="Rp" required v-model="details.sales_order_seller_pick_up_point.cost">
              </div>
              <div class="col col-md-9 form-group">
                <label for="resi" class="mb-0">Bukti Resi</label>
                <b-form-file id="resi" accept="image/*" @input="setResiImage" required></b-form-file>
              </div>
              <div class="col-12">
                <b-img v-if="resiImage" fluid
                  :src="resiImage"
                  :title="details.sales_order_seller_pick_up_point.tracking_number"
                  :alt="details.sales_order_seller_pick_up_point.tracking_number"
                />
                <b-img fluid
                  v-else-if="details.sales_order_seller_pick_up_point.image_url"
                  :src="details.sales_order_seller_pick_up_point.image_url"
                  :title="details.sales_order_seller_pick_up_point.tracking_number"
                  :alt="details.sales_order_seller_pick_up_point.tracking_number"
                />
              </div>
              <div class="col-12 d-flex">
                <button class="ml-auto btn btn-primary">Submit</button>
              </div>
            </form>
          </b-modal>
        </div>
        <div class="row" v-if="details.voice_message_url">
          <div class="col">QR Code</div>
          <div class="col text-right">
            <img
              :src="
                details.voice_message_url.split('voice_message')[0] +
                'qrcode/qrcode_' +
                details.sales_order_id +
                '.jpg'
              "
              height="100"
            />
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="col-md-12 pt-3 table-responsive">
        <table class="table">
          <thead>
            <th>SKU</th>
            <th>Name</th>
            <th>Qty</th>
            <th>Price</th>
            <th>Size</th>
            <th>Color</th>
            <th>Discount</th>
            <th v-if="details.reseller_id">Commission</th>
          </thead>
          <tbody>
            <template v-for="(item, i) in details.items">
              <tr :key="i">
                <td class="align-middle" :rowspan="item.notes ? '2' : '1'">
                  {{ item.sku }}
                </td>
                <td class="text-nowrap">{{ item.name }}</td>
                <td>{{ item.quantity }}</td>
                <td>Rp{{ formatPrice(item.quantity * item.price) }}</td>
                <td>{{ item.size }}</td>
                <td>{{ item.color }}</td>
                <td>Rp{{ formatPrice(item.discount_amount) }}</td>
                <td v-if="details.reseller_id">
                  Rp{{ formatPrice(item.quantity * item.fee_price) }}
                </td>
              </tr>
              <tr v-if="item.notes" :key="'note-' + i">
                <td :colspan="details.reseller_id ? '7' : '6'">
                  <b>Note: </b>{{ item.notes }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="text-center">
      <font-awesome icon="spinner" size="10x" spin />
    </div>
  </b-modal>
</template>
<script>
import { toaster } from "@/_helpers";

export default {
  name: "ModalOrderDetail",
  props: ["orderId"],
  data() {
    return {
      details: null,
      courierInfoStr: null,
      resi: null,
      resiImage: null,
      edit: false,
    };
  },
  computed: {
    isOwnMerchant() {
      return this.$store.getters.getMerchantData.merchant_pick_up_point_platform.name == 'OWN';
    }
  },
  methods: {
    async getOrderDetail(id) {
      this.edit = false
      this.courierInfoStr = null
      this.resiImage = null
      try {
        const res = await this.$api.order.getOrderDetail(id);
        if (res.status === 200) {
          this.details = res.data.data;
          if (this.details.sales_order_seller_pick_up_point) {
            this.edit = true
            this.setCourierInfo()
          } else this.details.sales_order_seller_pick_up_point = {}
        }
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async saveCourierInfo() {
      const data = this.details.sales_order_seller_pick_up_point
      const courierData = new FormData()
      courierData.append('data', JSON.stringify({
        sales_order_seller_id: this.orderId,
        cost: data.cost,
        tracking_number: data.tracking_number,
        courier_code: data.courier_code ?? '',
        courier_name: data.courier_name,
        courier_service_code: data.courier_service_code ?? '',
        courier_service_name: data.courier_service_name, 
      }))
      courierData.append('image', this.resi)
      try {
        const res = await this.$api.order[!this.edit ? 'addCourierInfo' : 'updateCourierInfo'](courierData)
        if (res.status === 200) this.getOrderDetail(this.orderId)
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async setCourierInfo() {
      const courierData = this.details.sales_order_seller_pick_up_point
      courierData.sales_order_seller_id = this.orderId
      this.courierInfoStr = [courierData.courier_name, courierData.tracking_number, `Rp${courierData.cost.toLocaleString('id')}`].join('\n')
      this.$bvModal.hide('modal-edit-courier-info')
      this.$emit('setCourier', courierData)
    },
    async setResiImage(event) {
      this.resi = event
      if (typeof FileReader != 'function') return console.error('File reader not supported')
      const reader = new FileReader()
      reader.onload = (read) => this.resiImage = read.target.result
      reader.readAsDataURL(this.resi)
    },
    formatPrice(value) {
      return value ? value.toLocaleString("id") : value;
    },
  },
  watch: {
    orderId(newVal) {
      this.details = null;
      this.getOrderDetail(newVal);
    },
  },
};
</script>
<style scoped>
#modal-order-detail .modal-body .row > :is(.col, .col-auto) {
  font-size: 0.965rem;
  line-height: 20px;
  word-break: break-word;
  word-wrap: break-word;
}
</style>
